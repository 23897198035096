import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import ContentWrapper from "../components/ContentWrapper";
import ResultCard from "../components/ResultCard";
import SEO from "../components/SEO";

const CardsHeader = styled.div`
  display: grid;
  grid-template-columns: 4fr 5fr 9fr;
  padding: 1rem 1rem;
  color: #999999;
`;

const ResultsPage = ({ data }) => {
  const results = data.allResultsJson.nodes;

  return (
    <Layout title="Results">
      <SEO pagetitle="Results" pagedesc="対局結果" />
      <ContentWrapper>
        <CardsHeader>
          <div>Date</div>
          <div>Match</div>
          <div>Player</div>
        </CardsHeader>
        {results.map((result) => (
          <ResultCard data={result} />
        ))}
      </ContentWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allResultsJson {
      nodes {
        date
        first
        first_result
        game
        note {
          name
          link
        }
        second
        second_result
      }
    }
  }
`;

export default ResultsPage;
