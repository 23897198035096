import * as React from "react";
import styled from "styled-components";

const WinOrLoseIconWrapper = styled.div`
  /* margin-top: 0.7rem;
  margin-left: 2rem; */
  padding: 0.7rem;
`;

const Win = styled.div`
  height: 30px;
  width: 30px;
  padding: 3px;
  border-radius: 50%;
  background: #13cf00;
`;

const Lose = styled.div`
  height: 30px;
  width: 30px;
  padding: 3px;
  border-radius: 50%;
  background: #d81920;
  text-align: center;
`;

const WinByDefault = styled.div`
  height: 30px;
  width: 30px;
  padding: 3px;
  border-radius: 10%;
  background: #13cf00;
  text-align: center;
`;

const LoseByDefault = styled.div`
  height: 30px;
  width: 30px;
  padding: 3px;
  border-radius: 50%;
  background: #d81920;
  text-align: center;
`;

const TXT = styled.div`
  margin: 0 auto;
  margin-top: 2px;
  text-align: center;
`;

const WinOrLoseIcon = ({ result }) => {
  return (
    <WinOrLoseIconWrapper>
      {"○" === result && (
        <Win>
          <TXT>W</TXT>
        </Win>
      )}
      {"●" === result && (
        <Lose>
          <TXT>L</TXT>
        </Lose>
      )}
      {"□" === result && (
        <WinByDefault>
          <TXT>W</TXT>
        </WinByDefault>
      )}
      {"■" === result && (
        <LoseByDefault>
          <TXT>L</TXT>
        </LoseByDefault>
      )}
    </WinOrLoseIconWrapper>
  );
};

export default WinOrLoseIcon;
